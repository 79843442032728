import React, {useEffect} from 'react';
import {graphql} from 'gatsby';
import {PageProps} from '../types';
import {getApiUrlFromData, getRecurlyPublicKeyFromData} from '../utils';
import {Layout} from '../components/Layout';
import PageWidth from '../components/PageWidth';
import {BillingInfo} from '../components/BillingInfo';
import {BillingInfoPageQuery} from './__generated__/BillingInfoPageQuery';
import {PageWrapper} from '../styles/page';
import {getPageData} from '../utils';
import {useAuth} from '../services/auth';

type BillingInfoPageProps = PageProps<BillingInfoPageQuery>;

const BillingInfoPage = (props: BillingInfoPageProps) => {
  const {isLoggedIn, attemptedFetch, getUserInfo} = useAuth();

  useEffect(() => {
    async function refreshUser() {
      if (attemptedFetch === false) {
        await getUserInfo(getApiUrlFromData(props.data));
      }
    }
    refreshUser();
  }, [isLoggedIn, attemptedFetch]);

  const pageData = getPageData(props.data);
  const recurlyPublicKey = getRecurlyPublicKeyFromData(props.data);

  return (
    <Layout>
      <PageWrapper grey>
        <PageWidth narrow>
          {isLoggedIn && (
            <BillingInfo
              apiBaseUrl={getApiUrlFromData(props.data)}
              recurlyPublicKey={recurlyPublicKey}
              planData={pageData}
            />
          )}
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BillingInfoPageQuery {
    en: allContentfulAmpifyWebPlans(filter: {node_locale: {eq: "en-GB"}}) {
      edges {
        node {
          premiumId
          premiumTitle
          premiumDescription
          premiumContactPrefix
          premiumContactDescription
          premiumContactLink
          registeredId
          registeredTitle
          registeredDescription
          registeredContactPrefix
          registeredContactDescription
          registeredContactLink
          freeId
          freeTitle
          freeDescription
          freeContactPrefix
          freeContactDescription
          freeContactLink
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
        recurlyPublicKey
      }
    }
  }
`;

export default BillingInfoPage;
