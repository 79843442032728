import React, {useState, FunctionComponent, useEffect} from 'react';
import {Elements, RecurlyProvider} from '@recurly/react-recurly';
import {Box, FormTitle, Spacer} from '../../styles/page';
import {BackLink} from './BillingInfo.styles';
import {parseCountriesFromResponse, PlanData} from '../../utils/plans';
import {navigate} from 'gatsby';
import {useAuth} from '../../services/auth';
import {useHasMounted} from '../../hooks/use-has-mounted';
import {
  PaymentsApi,
  UserApi,
  UpdateUserBillingInfo,
} from '@focusrite-novation/ampify-api';
import {createApi, createAuthedApi} from '../../api';
import {parseApiError} from '../../api';
import {
  RecurlyToken,
  User as UiUser,
} from '@focusritegroup/web-ui/dist/types/components/PaymentForm/PaymentForm.models';
import {PaymentFormRecurlyContainer} from '../PaymentFormRecurlyContainer/PaymentFormRecurlyContainer';

interface BillingInfoProps {
  apiBaseUrl: string;
  recurlyPublicKey: string;
  planData: PlanData;
}

export const BillingInfo: FunctionComponent<BillingInfoProps> = ({
  planData,
  recurlyPublicKey,
  apiBaseUrl,
}) => {
  const hasMounted = useHasMounted();
  const {isSubscriber, getUserInfo, attemptedFetch, user} = useAuth();
  const [success, setSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [allowedCountries, setAllowedCountries] = useState([]);

  const requestRecurlyToken = (form: HTMLFormElement) => {
    return new Promise<string>((resolve, reject) => {
      recurly.token(form, (error: any, token: RecurlyToken) => {
        if (error) {
          reject(error);
        } else {
          resolve(token.id);
        }
      });
    });
  };

  const handleSubmit = async (data: UpdateUserBillingInfo) => {
    setSuccess('');

    try {
      const api = createAuthedApi(PaymentsApi, apiBaseUrl);
      await api.updateUserBillingInfo(data);
      setSuccess('Successfully updated your billing information');
    } catch (error) {
      try {
        const apiError = parseApiError(error);
        setErrorMessage(apiError.message);
      } catch (error) {
        console.error(error);
        setErrorMessage(
          'Sorry, there was a problem updating your subscription.'
        );
      }
    }
  };

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      if (!attemptedFetch) {
        await getUserInfo(apiBaseUrl);
      }

      if (attemptedFetch && !isSubscriber) {
        navigate('/my-account');
      }
    };

    checkSubscriptionStatus();
  }, [attemptedFetch, isSubscriber, getUserInfo]);

  useEffect(() => {
    async function fetchAllowedCountries() {
      try {
        const api = createApi(PaymentsApi, apiBaseUrl);
        const countriesResponse = (await api.getSupportedCountries()).data;
        const countries = countriesResponse.hasOwnProperty('countries')
          ? // @ts-ignore
            parseCountriesFromResponse(countriesResponse.countries)
          : countriesResponse;

        setAllowedCountries(countries);
      } catch (error) {
        // if this endpoint fails we can safely ignore it as the server
        // will reject any incorrect country - this code is mostly for UX
      }
    }

    fetchAllowedCountries();
  }, []);

  return (
    hasMounted && (
      <RecurlyProvider publicKey={recurlyPublicKey}>
        <Elements>
          <Box>
            <FormTitle>Change Billing Info</FormTitle>
            <Spacer />
            <BackLink to="/my-account">Back to My Account</BackLink>
            <Spacer />
            <PaymentFormRecurlyContainer
              user={user}
              supportedCountries={allowedCountries}
              submitButtonText="Update Billing Info"
              planId={planData.premiumId}
              subscribe={handleSubmit}
              error={errorMessage}
              useUpdateBillingForm={true}
              successMessage={success}
              billingDuration="month"
            />
          </Box>
        </Elements>
      </RecurlyProvider>
    )
  );
};

export default BillingInfo;
